import React, { useState, useEffect, useRef } from 'react';
import { FaArrowRight, FaArrowLeft, FaMicrophone } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card } from '../components/CommonComponents';
import { useCookies } from 'react-cookie';
import { useQuestionContext } from '../context/QuestionContext';
import { fetchQuestions } from '../api/serverAPI'; // Import the function from server.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { medbotVoices } from "../components/Mapping";

const QuestionPage = ({jobId}) => {
  const { questions, setQuestions, answers, setAnswers } = useQuestionContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState([]);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [cookies] = useCookies(['email', 'jobId']); // Retrieve email from cookies
  const email = cookies.email; // Use the email from cookies
  // const jobId = cookies.jobId;
  const [, setCookie] = useCookies(['jobId']);
  const audioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    const getQuestions = async () => {
      try {
        const questions = await fetchQuestions(email, jobId); // API call to get questions
        setQuestions(questions);

        // Initialize timeLeft with different times for each question type
        const initialTimes = questions.map((question) => {
          switch (question.typques) {
            case 'Subjective':
              return 180; // 3 minutes
            case 'Fill in the Blanks':
              return 90; // 1 minute 30 seconds
            case 'True or False':
            case 'Multiple Choice Questions':
              return 60; // 1 minute
            default:
              return 60; // Default time
          }
        });

        setTimeLeft(initialTimes);
        setAnswers(Array(questions.length).fill('')); // Initialize answers
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getQuestions();
    // startCamera();
    // startStream();
  }, [email, setQuestions, setAnswers]);

  useEffect(() => {
    if (questions.length > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = [...prevTimeLeft];
          if (newTimeLeft[currentQuestionIndex] > 0) {
            newTimeLeft[currentQuestionIndex] -= 1;
          } else {
            setIsTimeUp(true);
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
    if (!isTimeUp && questions.length > 0) {
      console.log(questions)
      console.log(currentQuestionIndex)
      console.log(questions[currentQuestionIndex])
      // speak(questions[currentQuestionIndex].question);
    }
  }, [currentQuestionIndex, isTimeUp, questions]);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      alert('Interview has started. You cannot go back or switch tab.');

      // Push the current state again to prevent going back
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    // Push the initial state to ensure the back button doesn't navigate away
    window.history.pushState(null, '', window.location.href);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsTimeUp(false);
    } else {
      navigate('/summary');
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsTimeUp(false);
    }
  };

  const startSpeechRecognition = () => {
    if (!isTimeUp) {
      if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.start();

        recognition.onresult = (event) => {
          const transcript = event.results[0][0].transcript.toLowerCase().trim();
          console.log('Recognized text:', transcript);

          setAnswers((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            const currentQuestion = questions[currentQuestionIndex];

            if (currentQuestion.typques === 'Multiple Choice Questions' || currentQuestion.typques === 'True or False') {
              const options = currentQuestion.options.replace(/[\[\]']/g, '').split(',').map((option) => option.trim().toLowerCase());
              const matchedOption = options.find((option) => transcript.includes(option));
              console.log('Matched option:', matchedOption);

              if (matchedOption) {
                newAnswers[currentQuestionIndex] = matchedOption;
              }
            } else {
              newAnswers[currentQuestionIndex] = transcript;
            }
            return newAnswers;
          });
        };

        recognition.onerror = (event) => {
          console.error(event.error);
        };
      } else {
        alert("Sorry, your browser doesn't support speech recognition.");
      }
    }
  };

  const handleTextInput = (event) => {
    if (!isTimeUp) {
      const newAnswer = event.target.value;
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[currentQuestionIndex] = newAnswer;
        return updatedAnswers;
      });
    }
  };


  const speak = async (text) => {
    const audio = audioRef.current;
    let voice = cookies.voice || medbotVoices[0].value;
    const body = JSON.stringify({ voice: voice, text: text });

    try {
      const response = await fetch('https://dev.genai.krtrimaiq.ai:8000/text_to_speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const mediaSource = new MediaSource();
      audio.src = URL.createObjectURL(mediaSource);

      mediaSource.addEventListener('sourceopen', async () => {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
        const reader = response.body.getReader();

        const pump = async () => {
          try {
            const { done, value } = await reader.read();
            if (done) {
              mediaSource.endOfStream();
              audio.play();
              audio.onended = () => {
                setShowButton(true);
              };
              return;
            }
            sourceBuffer.addEventListener('updateend', () => {
              if (!sourceBuffer.updating) {
                pump();
              }
            });
            sourceBuffer.appendBuffer(value);
          } catch (error) {
            console.error('Error reading stream:', error);
          }
        };

        await pump();
      });

      audio.oncanplaythrough = () => {
        audio.play();
      };
    } catch (error) {
      console.error('Error fetching or playing audio:', error);
    }
  };

  useEffect(() => {
    if (!isTimeUp && questions.length > 0 && isMuted) {
      speak(questions[currentQuestionIndex].question)
      // speakQuestion(questions[currentQuestionIndex].question);
    }
  }, [currentQuestionIndex, isTimeUp, questions]);


  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !audio.muted;
    setIsMuted(!isMuted);
  };

  const renderQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    switch (currentQuestion.typques) {
      case 'Subjective':
        return (
          <textarea
            value={answers[currentQuestionIndex]}
            onChange={handleTextInput}
            className="w-full p-2 border rounded-lg"
            rows="4"
            placeholder="Type your answer here..."
            disabled={isTimeUp}
          />
        );
      case 'Fill in the Blanks':
        return (
          <input
            type="text"
            value={answers[currentQuestionIndex]}
            onChange={handleTextInput}
            className="w-full p-2 border rounded-lg"
            placeholder="Type your answer here..."
            disabled={isTimeUp}
          />
        );
      case 'True or False':
      case 'Multiple Choice Questions':
        const options = currentQuestion.options
          .replace(/[\[\]']/g, '')
          .split(',')
          .map((option) => option.trim());
        return (
          <div className="space-y-2">
            {options.map((option, index) => (
              <div key={index} className="flex items-center">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={(e) =>
                      setAnswers((prevAnswers) => {
                        const newAnswers = [...prevAnswers];
                        newAnswers[currentQuestionIndex] = e.target.value;
                        return newAnswers;
                      })
                    }
                    disabled={isTimeUp}
                  />
                  <span className="ml-2">{option}</span>
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div className='text-center font-bold mt-4'>Loading...</div>;
  }

  if (error) {
    return <div className='text-center font-bold mt-4'>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="flex flex-col items-center p-4">
        <div className="w-64 h-32 top-20 left-16 absolute flex items-center justify-center">
          <Card className="text-lg">
            Time Left: {Math.floor(timeLeft[currentQuestionIndex] / 60)}:
            {timeLeft[currentQuestionIndex] % 60 < 10
              ? `0${timeLeft[currentQuestionIndex] % 60}`
              : timeLeft[currentQuestionIndex] % 60}
          </Card>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg fixWidth">
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg">
              Question {currentQuestionIndex + 1} of {questions.length}
            </p>

          </div>
          <div className="mb-4">
            <p className="text-xl">{questions[currentQuestionIndex].question}</p>
          </div>
          <div className="mb-4">{renderQuestion()}</div>
          <div className="flex justify-between">
            <div>
              <Button size="white" onClick={startSpeechRecognition} disabled={isTimeUp}>
                <FaMicrophone size={16} />
              </Button>
            </div>
            <div className="flex items-center relative ml-auto">
              <Link to="/summary" className="mx-1">
              <Button size="white" className="text-wrap" >Skip to end</Button>
              </Link>
              <Button size="white" className="mx-1" onClick={prevQuestion}>
                <FaArrowLeft />
              </Button>
              <Button size="white" className="mx-1" onClick={nextQuestion}>
                <FaArrowRight />
              </Button>
            </div>

          </div>

        </div>
      </div>
      <audio ref={audioRef} style={{ display: 'none' }} />
      <div className="relative w-full">
        <button
          onClick={toggleMute}
          className="fixed left-20 bottom-1 bg-blue-500 rounded-full text-[#23479F] p-2 shadow-lg"
        >
          <FontAwesomeIcon icon={isMuted ? faVolumeUp : faVolumeMute} size="lg"
            onClick={() => {
              setIsMuted(!isMuted);
              !isMuted ? speak(questions[currentQuestionIndex].question) : setIsMuted(false);
            }}
            />
        </button>
      </div>
    </div>
  );
};

export default QuestionPage;
